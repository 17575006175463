import {
  dataAppleRequest
} from '@/api'

import {
  dateOperating
} from '@/fmlib'

const stringify = (key, value) => {
  if (Array.isArray(value)) {
    return value.join(',')
  } else if (value instanceof Date) {
    return dateOperating.computeDay({days: 0, date: value, format: 'yy-mm-dd hh:mm:ss'})
  } else {
    return value
  }
}

export const diff = (source, update) => {
  let newData = {}
  let hisData = {}

  if (!source) {
    return {hisData: {}, newData: update}
  }

  let sourceKey = Object.keys(source)
  let keys = [...sourceKey, ...Object.keys(update).filter(k => !sourceKey.includes(k))]
  for (const key of keys) {
    if (source[key] !== update[key] || key === 'workerId') {
      newData[key] = update[key]
      hisData[key] = source[key]
    }
  }
  return {newData, hisData}
}

export const applyFormSubmit = async ({vm, id, sourceData, data, userId, dealApplyData, applicableType}) => {
  let res = diff(sourceData, data)
  res = dealApplyData ? dealApplyData(res, sourceData) : res
  if (res === false) {
    vm.$notify({
      title: '系统提示',
      message: '数据无变化',
      type: 'info'
    })
    throw new Error()
  }
  try {
    let param = {
      dataType: applicableType,
      dataId: id,
      type: id ? 'edit' : 'add',
      applyUserId: userId,
      hisData: JSON.stringify(res.hisData, stringify),
      newData: JSON.stringify(res.newData, stringify),
      status: 'wait'
    }
    await dataAppleRequest.add(param)
    vm.$notify({
      title: '系统提示',
      message: '申请成功',
      type: 'success'
    })
  } catch (error) {
    vm.$notify({
      title: '系统提示',
      message: '申请失败',
      type: 'error'
    })
    throw new Error(error)
  }
}

export const applyDel = async ({vm, id, sourceData, userId, applicableType}) => {
  await dataAppleRequest.add({
    dataType: applicableType,
    dataId: id,
    type: 'del',
    applyUserId: userId,
    hisData: JSON.stringify(sourceData, stringify),
    status: 'wait'
  })
  vm.$notify({
    title: '系统提示',
    message: '申请成功',
    type: 'success'
  })
}

