<template>
  <sign-block
    v-if="display"
    title-text="工作记录"
    :request="request"
    :titleMenus="readOnly ? [] : [{key: 'add', label: '新增'}]"
    :column-list="columnList"
    :form-parms-add="formParmsAdd"
    table-size="large"
    :pageFormSubmit="pageFormSubmit"
    :pageDelData="pageDelData"
    :tableActions="tableActions"
    :form-parms-update="formParmsAdd"
    :initAddFormData="initAddFormData"
    :dealFormData="dealFormData"
    @dataUpdate="dataUpdate"
    :dealData="dealData"
    :initSearchData="{workerId: this.workerId}"
    :tableActionsFixed="true"
    :need-data-file="true">
  </sign-block>
</template>

<script>
import {
  applyFormSubmit,
  applyDel
} from './apply'

import {
  hisWorkRequest as request,
  workerRequest
} from '@/api'

import {
  dateOperating
} from '@/fmlib'

export default {
  props: {
    workerId: {
      type: [String, Number]
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    display: {
      type: Boolean,
      default: true
    },
    historyDate: {
      type: Date,
      default: null
    }
  },
  created () {
    if (!this.display) {
      this.loadData()
    }
  },
  methods: {
    dealApplyData ({hisData, newData}, data) {
      hisData.workerId = data.workerId
      return {hisData, newData}
    },
    async pageDelData (parm) {
      await applyDel({
        vm: this,
        id: parm.vm.chooseData && parm.vm.chooseData.id ? parm.vm.chooseData.id : undefined,
        sourceData: parm.vm.chooseData,
        userId: this.$store.getters.userInfo.id,
        applicableType: 'his_work'
      })
    },
    async pageFormSubmit (parm) {
      await applyFormSubmit({
        vm: this,
        id: parm.vm.chooseData && parm.vm.chooseData.id ? parm.vm.chooseData.id : undefined,
        sourceData: parm.vm.chooseData,
        userId: this.$store.getters.userInfo.id,
        data: parm.data,
        dealApplyData: this.dealApplyData,
        applicableType: 'his_work'
      })
    },
    timeBj (t1, t2) {
      return dateOperating.computeDay({days: 0, date: t1}) > dateOperating.computeDay({days: 0, date: t2})
    },
    dealFormData (data) {
      if (data.isOver === '否') {
        data.endTime = null
      }
      if (data.startTime && data.endTime && this.timeBj(data.startTime, data.endTime)) {
        this.$notice.info({
          title: '系统提示',
          desc: '结束时间需要大于开始时间。'
        })
        throw Error()
      }
      return data
    },
    dealData (data) {
      return !this.historyDate ? data : data.filter(v => new Date(v.startTime) <= this.historyDate)
    },
    async loadData () {
      let parm = {
        workerId: this.workerId
      }
      let data = await request.get(parm)
      data.forEach(v => v.isOver = v.endTime ? '是' : '否')
      if (!data.length) {
        this.$emit('completeness', 0)
      } else {
        const keys = this.formParmsAdd.map(v => v.key)
        const num = data.reduce((a, item) => {
          return a + keys.reduce((c, key) => {
            if (typeof key === 'string' && ((Array.isArray(item[key]) && item[key].length) || (!Array.isArray(item[key]) && item[key]))) {
              return c + 1
            } else {
              return c
            }
          }, 0)
        }, 0)
        this.$emit('completeness', num / (data.length * keys.length))
      }

      this.$emit('dataOk', data && data.length > 0)
      return data
    },
    initAddFormData () {
      return {
        workerId: this.workerId
      }
    },
    dataUpdate (data) {
      if (data.workerId) {
        this.updateWorkerGl(data.workerId)
      }
    },
    async updateWorkerGl (workerId) {
      let hisWorkData = await request.get({workerId})
      hisWorkData.forEach(v => v.endTime = v.endTime || dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd'}))
      hisWorkData = hisWorkData.filter(v => v.startTime && v.endTime && (new Date(v.startTime).getTime() < new Date(v.endTime).getTime() || new Date(v.startTime).getTime() === new Date(v.endTime).getTime()))
      let years = []
      hisWorkData.forEach(v => years.push([Number(v.startTime.slice(0, 4)), Number(v.endTime.slice(0, 4))]))
      let years2 = []
      years.forEach((v) => {
        let cYear = v[0]
        while (cYear < v[1]) {
          if (!years2.includes(cYear)) {
            years2.push(cYear)
          }
          cYear += 1
        }
        if (!years2.includes(v[1])) {
          years2.push(v[1])
        }
      })
      workerRequest.update(workerId, {
        maritalStatus: years2.length
      })
    }
  },
  computed: {
    tableActions () {
      return this.readOnly ? [] : [{key: 'edit', label: '修改'}, {key: 'del', label: '删除'}]
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '工作单位',
          key: 'orgName',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '担任职务',
          key: 'jobTitle',
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '开始时间',
          key: 'startTime',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '是否已结束',
          key: 'isOver',
          selectDatas: [{key: '是', label: '是'}, {key: '否', label: '否'}],
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '结束时间',
          key: 'endTime',
          show: (formData) => {
            return formData.isOver === '是'
          },
          check: {
            required: true
          }
        }]
        return data
      }
    },
    columnList () {
      let data = [{
        title: '员工姓名',
        field: 'workerName',
        sort: true
      },
      {
        title: '员工工号',
        field: 'workerCode',
        sort: true
      },
      {
        title: '工作单位',
        field: 'orgName',
        sort: true
      },
      {
        title: '担任职务',
        field: 'jobTitle',
        sort: true
      },
      {
        title: '开始时间',
        field: 'startTime',
        sort: true,
        dataType: Date,
        render: (h, rowData) => {
          return h('div', rowData && rowData.startTime ? rowData.startTime.slice(0, 10) : '-')
        }
      },
      {
        title: '结束时间',
        field: 'endTime',
        sort: true,
        dataType: Date,
        render: (h, rowData) => {
          return h('div', rowData && rowData.endTime ? rowData.endTime.slice(0, 10) : '至今')
        }
      }]
      return data
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  }
}
</script>
