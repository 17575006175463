<template>
  <worker-form
    :source-data="choose"
    :org-tree="orgTree"
    :fun-auth="{
      edit: false,
      add: false
    }"
    :read-only="readOnly"
    v-if="display"
    :type="type"
    ref="worker_form_p"></worker-form>
</template>

<script>
import {
  workerJobTitleRequest,
  workerRequest,
  dataAppleRequest,
  orgRequest
} from '@/api'

import WorkerForm from '@/views/worker/worker/workerForm'

import {
  getBirthByIdNo,
  getAge
} from '@/syslib/tools'

export default {
  components: { WorkerForm },
  props: {
    workerId: {
      type: [String, Number]
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: null
    },
    display: {
      type: Boolean,
      default: true
    },
    historyDate: {
      type: Date,
      default: null
    }
  },
  data () {
    return {
      orgTree: [],
      choose: null,
      positionOrgMap: {},
      workerJobTitle: [],
      src: null,
      sourceData: null
    }
  },
  computed: {
    positionList () {
      return this.$store.getters.positionList
    },
    positionMap () {
      let data = {}
      this.positionList.forEach(v => data[v.key] = v.label)
      return data
    },
    completeness () {
      if (!this.choose) {
        return null
      }
      let src = this.src
      if (this.type === 'base') {
        let checks = ['name', 'sex', 'clan', () => (src !== null), 'politicalStatus', 'idNo', 'birth', 'age', 'sf', 'education', 'hometown', 'phone', 'agreementCode', 'graduatedSchool']
        return checks.reduce(this.checksFn, 0) / checks.length
      } else if (this.type === 'work') {
        let checks = ['code', 'orgName', 'positionId', 'positionList', 'type',  'jobTypeC', 'correctionDate', 'entryDate', 'jobType', 'shuangjiantiao', 'email', 'jobTitle', 'employmentForm', 'discipline']
        return checks.reduce(this.checksFn, 0) / checks.length
      } else {
        return null
      }
    }
  },
  watch: {
    completeness (v) {
      this.$emit('completeness', v)
    }
  },
  async created () {
    if (this.workerId) {
      await this.$store.dispatch('loadPositionList')
      await this.$store.dispatch('loadOrgTypeList')
      await this.loadWjt()
      await this.loadPositionOrg()
      this.loadData()
    }
  },
  methods: {
    checksFn (a, b) {
      if (typeof b === 'string' && ((Array.isArray(this.choose[b]) && this.choose[b].length) || (!Array.isArray(this.choose[b]) && this.choose[b]))) {
        return a + 1
      } else if (typeof b === 'function' && b()) {
        return a + 1
      } else {
        return a
      }
    },
    idNoChange (data) {
      let birth = getBirthByIdNo(data)
      if (birth) {
        this.choose.birth = birth
      }
    },
    birthChange (birth) {
      this.choose.birth = birth ? birth.replace(/年|月/g, '-').replace('日', '') : ''
      this.choose.age = getAge(this.choose.birth)
    },
    dealPositionOrg (orgTree, orgList) {
      orgTree.forEach((v) => {
        if (v.positions && v.positions.length > 0) {
          v.positions.forEach(v2 => this.positionOrgMap[v2.id] = [v].concat(orgList))
        }
        if (v.child && v.child.length > 0) {
          this.dealPositionOrg(v.child, [v].concat(orgList))
        }
      })
    },
    dealOrgData (orgTree, orgList) {
      orgTree.forEach((v) => {
        orgList.push(v)
        if (v.child && v.child.length > 0) {
          this.dealOrgData(v.child, orgList)
        }
      })
    },
    async loadPositionOrg () {
      let orgList = []
      let orgTree = await orgRequest.getAllTree()
      this.dealPositionOrg(orgTree, [])
      this.dealOrgData(orgTree, orgList)
      this.orgTree = orgList
    },
    async loadWjt () {
      this.workerJobTitle = await workerJobTitleRequest.get()
    },
    testEdited () {
      let editKeys = this.getDiffKeys()
      console.log(editKeys)
      return editKeys.length > 0
    },
    async save () {
      let vm = this.$refs['worker_form_p']
      if (vm && typeof vm.getFormData === 'function') {
        let data = vm.getFormData()
        if (data) {
          this.choose = data
        } else {
          return
        }
      } else {
        return
      }
      this.saveModalSp()
    },
    getDiffKeys () {
      let vm = this.$refs['worker_form_p']
      if (vm && typeof vm.getFormData === 'function') {
        let data = vm.getFormData()
        if (data) {
          this.choose = data
        } else {
          return
        }
      } else {
        return
      }
      let diffData = []
      Object.keys(this.choose).forEach((key) => {
        let item = {
          key: key,
          update: this.choose[key],
          old: this.sourceData[key] || null
        }
        let notCheckKeys = ['age', 'isDel', 'educationList', 'positionList', 'workerJobTitles', 'hisWorkDatas', 'orgType', 'positionData', 'positionDatas', 'workerCertificateDoctorPraDatas', 'workerCertificateDoctorQuaDatas', 'workerCertificateProHireDatas', 'workerCertificateProQuaDatas', 'workerEduDatas', 'workerJobTitleDatas', 'fWjt', 'lWjt']
        if (key === 'positionIds') {
          if (Array.isArray(item.update)) {
            item.update = item.update.join(',')
          }
          if (Array.isArray(item.old)) {
            item.old = item.old.join(',')
          }
          if ((item.update || item.old) && (item.update !== item.old)) {
            diffData.push(item)
          }
        } else if (item.update !== item.old && (item.update || item.old) && !notCheckKeys.includes(key) && !key.includes('orgTypeValue')) {
          diffData.push(item)
        }
      })
      return diffData
    },
    async saveModalSp () {
      let editKeys = this.getDiffKeys()
      if (editKeys.length === 0) {
        this.$notice.info({
          title: '系统提示',
          desc: '无数据变更，请重新修改数据。'
        })
        return
      }
      let hisData = {}
      let newData = {}

      editKeys.forEach(item => {
        hisData[item.key] = item.old
        newData[item.key] = item.update
      })

      let data = {
        dataType: 'worker',
        type: this.choose.id ? 'edit' : 'add',
        applyUserId: this.$store.getters.userInfo.id,
        hisData: JSON.stringify(hisData),
        newData: JSON.stringify(newData),
        status: 'wait'
      }

      if (this.choose.id) {
        data.dataId = this.choose.id
      }

      await dataAppleRequest.add(data)

      this.$notice.info({
        title: '系统提示',
        desc: (this.choose.id ? '修改' : '新增') + '申请已提交。'
      })
      
      this.choose = Object.assign({}, this.sourceData)
    },
    async loadData () {
      let wjt = this.historyDate ? this.workerJobTitle.filter(v => new Date(v.startDate) <= this.historyDate) : this.workerJobTitle
      let datas = await workerRequest.get({
        workerId: this.workerId
      })
      this.$emit('dataOk', datas && datas.length > 0)
      datas.forEach((v) => {
        v.positionIds = v.positionIds ? v.positionIds.split(',') : []
        v.positionList = v.positionIds
        v.positionList = v.positionList.map(v1 => Number(v1))
        v.age = getAge(v.birth, this.historyDate ? this.historyDate : new Date())
        let eduHis = v.eduHis ? JSON.parse(v.eduHis).filter(v => v.edu) : []
        v.fEduHis = eduHis.length > 0 ? eduHis[0] : null
        v.lEduHis = eduHis.length > 0 ? eduHis[eduHis.length - 1] : null
        v.workerJobTitles = wjt.filter(v2 => v2.workerId === v.id)
        v.fWjt = v.workerJobTitles.length > 0 ? v.workerJobTitles[0] : null
        let cWorkerJobTitles = v.workerJobTitles.filter(v2 => !v2.endDate)
        v.lWjt = cWorkerJobTitles.length > 0 ? cWorkerJobTitles[cWorkerJobTitles.length - 1] : null
        if (v.positionId && this.positionOrgMap[v.positionId]) {
          this.positionOrgMap[v.positionId].filter(v1 => v1.orgTypeLevel).forEach((v2) => {
            let key = 'orgType' + ['零', '一', '二', '三', '四', '五'].indexOf(v2.orgTypeLevel.replace('等级', ''))
            v[key] = v2.name
          })
        }

        v.positionIdText = v.positionId && this.positionMap[v.positionId] ? this.positionMap[v.positionId] : null
        v.positionIdsText = v.positionList && v.positionList.length > 0 ? v.positionList.map(v => this.positionMap[v]).filter(v => v).join(',') : null
        v.dyxlqk = v.fEduHis ? v.fEduHis.edu : null
        v.dibysj = v.fEduHis && v.fEduHis.date && v.fEduHis.date.length ? v.fEduHis.date[1] : null
        v.zgxl = v.lEduHis ? v.lEduHis.edu : null
        v.zgzy = v.lEduHis ? v.lEduHis.pro : null
        v.zgbysj = v.lEduHis && v.lEduHis.date && v.lEduHis.date.length ? v.lEduHis.date[1] : null
        v.srgwlb = v.fWjt && v.fWjt.jobTitleType ? v.fWjt.jobTitleType : null
        v.srgwjb = v.fWjt && v.fWjt.type ? (v.fWjt.type === '医师' ? v.fWjt.yTitle : v.fWjt.hTitle) : null
        v.srgwsj = v.fWjt && v.fWjt.startDate ? v.fWjt.startDate.slice(0, 10) : null
        v.xrgwlb = v.lWjt && v.lWjt.jobTitleType ? v.lWjt.jobTitleType : null
        v.xrgwjb = v.lWjt && v.lWjt.type ? (v.lWjt.type === '医师' ? v.lWjt.yTitle : v.lWjt.hTitle) : null
        v.xrgwsj = v.lWjt && v.lWjt.startDate ? v.lWjt.startDate.slice(0, 10) : null
      })
      this.sourceData = datas && datas.length > 0 ? datas[0] : {}
      this.choose = Object.assign({}, this.sourceData)
    }
  }
}
</script>
