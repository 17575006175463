<template>
  <sign-block
    v-if="display"
    title-text="职业资格证书"
    :request="request"
    :titleMenus="readOnly ? [] : [{key: 'add', label: '新增'}]"
    :column-list="columnList"
    :form-parms-add="formParmsAdd"
    @dataFileUpdate="dataFileUpdate"
    :pageFormSubmit="pageFormSubmit"
    :pageDelData="pageDelData"
    :data-file-parm="{pDirPath: '/worker_certificate/'}"
    :dataFilefuns="dataFilefuns"
    table-size="large"
    :tableActions="tableActions"
    :form-parms-update="formParmsAdd"
    :initAddFormData="initAddFormData"
    :dealData="dealData"
    :tableActionsFixed="true"
    :need-data-file="true">
  </sign-block>
</template>

<script>
import {
  applyFormSubmit,
  applyDel
} from './apply'

import {
  workerCertificateRequest as request
} from '@/api'

import {
  dateOperating
} from '@/fmlib'

export default {
  props: {
    workerId: {
      type: [String, Number]
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    display: {
      type: Boolean,
      default: true
    },
    historyDate: {
      type: Date,
      default: null
    }
  },
  methods: {
    dealApplyData ({hisData, newData}, data) {
      hisData.workerId = data.workerId
      if (!data || !data.id) {
        newData.type = data.type
      }
      return {hisData, newData}
    },
    async pageDelData (parm) {
      await applyDel({
        vm: this,
        id: parm.vm.chooseData && parm.vm.chooseData.id ? parm.vm.chooseData.id : undefined,
        sourceData: parm.vm.chooseData,
        userId: this.$store.getters.userInfo.id,
        applicableType: 'worker_certificate_doctor_qua'
      })
    },
    async pageFormSubmit (parm) {
      await applyFormSubmit({
        vm: this,
        id: parm.vm.chooseData && parm.vm.chooseData.id ? parm.vm.chooseData.id : undefined,
        sourceData: parm.vm.chooseData,
        userId: this.$store.getters.userInfo.id,
        data: parm.data,
        dealApplyData: this.dealApplyData,
        applicableType: 'worker_certificate_doctor_qua'
      })
    },
    dealData (data) {
      return !this.historyDate ? data : data.filter(v => new Date(v.getDate) <= this.historyDate)
    },
    async dataFileUpdate (parm, pageVm) {
      await request.update(pageVm.chooseData.id, {
        imgUploadTime: dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'})
      })
      pageVm.loadData()
    },
    initAddFormData () {
      return {
        workerId: this.workerId,
        type: 'doctor_qua'
      }
    },
    async loadData () {
      let parm = {
        workerId: this.workerId,
        type: 'doctor_qua'
      }
      let data = await request.get(parm)
      this.$emit('dataOk', data && data.length > 0)
      data.forEach(v => {
        v.typeText = (this.$store.getters.certificateTypeList.find(i => i.key === v.type) || {label: v.type}).label
        v.imgStatusText = v.imgUploadTime ? '已上传' : '未上传'
      })

      if (!data.length) {
        this.$emit('completeness', 0)
      } else {
        const keys = this.formParmsAdd.filter(v => !['type'].includes(v.key)).map(v => v.key)
        const num = data.reduce((a, item) => {
          return a + keys.reduce((c, key) => {
            if (typeof key === 'string' && ((Array.isArray(item[key]) && item[key].length) || (!Array.isArray(item[key]) && item[key]))) {
              return c + 1
            } else {
              return c
            }
          }, 0)
        }, 0)
        this.$emit('completeness', num / (data.length * keys.length))
      }

      return data
    }
  },
  created () {
    if (!this.display) {
      this.loadData()
    }
  },
  computed: {
    dataFilefuns () {
      return {
        get: this.$authFunProxy['worker.worker_certificate_doctor_qua-getDataFile'],
        upload: !this.readOnly && this.$authFunProxy['worker.worker_certificate_doctor_qua-updateDataFile'],
        down: !this.readOnly && this.$authFunProxy['worker.worker_certificate_doctor_qua-downDataFile'],
        del: !this.readOnly && this.$authFunProxy['worker.worker_certificate_doctor_qua-delDataFile'],
        mkdir: !this.readOnly && this.$authFunProxy['worker.worker_certificate_doctor_qua-mkdirDataFile']
      }
    },
    tableActions () {
      return this.readOnly ? [{key: 'data_file', label: '扫描件'}] : [{key: 'data_file', label: '扫描件'}, {key: 'edit', label: '修改'}, {key: 'del', label: '删除'}]
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          selectDatas: this.$store.getters.certificateTypeList,
          label: '证件类型',
          key: 'type',
          fmDisabled: true
        },
        {
          type: 'input',
          label: '证书编码',
          key: 'code'
        },
        {
          type: 'input',
          label: '发证机关',
          key: 'issueOrg'
        },
        {
          type: 'input',
          label: '签发人',
          key: 'issueUser'
        },
        {
          type: 'datePicker',
          label: '发证日期',
          key: 'getDate'
        },
        {
          type: 'input',
          label: '毕业学校',
          key: 'gradSchool'
        },
        {
          type: 'input',
          label: '专业',
          key: 'pro'
        },
        {
          type: 'input',
          label: '类别',
          key: 'mType'
        },
        {
          type: 'input',
          label: '证件状态',
          key: 'status'
        }]
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '编号',
          fixed: 'left',
          field: 'id',
          sort: true,
          width: 100
        },
        {
          title: '证书编码',
          fixed: 'left',
          field: 'code',
          sort: true
        },
        {
          title: '类型',
          fixed: 'left',
          field: 'typeText',
          sort: true
        },
        {
          title: '员工姓名',
          field: 'workerName',
          sort: true
        },
        {
          title: '发证机关',
          field: 'issueOrg',
          sort: true
        },
        {
          title: '签发人',
          field: 'issueUser',
          sort: true
        },
        {
          title: '发证日期',
          field: 'getDate',
          sort: true,
          dataType: Date,
          width: 150,
          render: (h, rowData) => {
            return h('div', rowData && rowData.getDate ? rowData.getDate.slice(0, 10) : '-')
          }
        },
        {
          title: '毕业学校',
          field: 'gradSchool',
          sort: true
        },
        {
          title: '专业',
          field: 'pro',
          sort: true
        },
        {
          title: '类别',
          field: 'mType',
          sort: true
        },
        {
          title: '证件状态',
          field: 'status',
          sort: true
        },
        {
          title: '扫描件状态',
          field: 'imgStatusText',
          sort: true
        },
        {
          title: '最近更新扫描件时间',
          field: 'imgUploadTime',
          width: 180,
          sort: true,
          dataType: Date
        }]
        return data
      }
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  }
}
</script>
