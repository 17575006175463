<template>
  <sign-block
    v-if="display"
    title-text="专业技术资格证书"
    :request="request"
    @dataFileUpdate="dataFileUpdate"
    :data-file-parm="{pDirPath: '/worker_certificate/'}"
    :titleMenus="readOnly ? [] : [{key: 'add', label: '新增'}]"
    :column-list="columnList"
    :form-parms-add="formParmsAdd"
    :pageFormSubmit="pageFormSubmit"
    :pageDelData="pageDelData"
    table-size="large"
    :dataFilefuns="dataFilefuns"
    :tableActions="tableActions"
    :form-parms-update="formParmsAdd"
    :initAddFormData="initAddFormData"
    :dealData="dealData"
    :initSearchData="{workerId: this.workerId, type: 'pro_qua'}"
    :tableActionsFixed="true"
    :need-data-file="true">
  </sign-block>
</template>

<script>
import {
  applyFormSubmit,
  applyDel
} from './apply'

import {
  workerCertificateRequest as request
} from '@/api'

import {
  dateOperating
} from '@/fmlib'

export default {
  created () {
    if (!this.readOnly) {
      this.$store.dispatch('loadCertificateList')
    }
    if (!this.display) {
      this.loadData()
    }
  },
  props: {
    workerId: {
      type: [String, Number]
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    display: {
      type: Boolean,
      default: true
    },
    historyDate: {
      type: Date,
      default: null
    }
  },
  methods: {
    dealApplyData ({hisData, newData}, data) {
      hisData.workerId = data.workerId
      if (!data || !data.id) {
        newData.type = data.type
      }
      return {hisData, newData}
    },
    async pageDelData (parm) {
      await applyDel({
        vm: this,
        id: parm.vm.chooseData && parm.vm.chooseData.id ? parm.vm.chooseData.id : undefined,
        sourceData: parm.vm.chooseData,
        userId: this.$store.getters.userInfo.id,
        applicableType: 'worker_certificate'
      })
    },
    async pageFormSubmit (parm) {
      await applyFormSubmit({
        vm: this,
        id: parm.vm.chooseData && parm.vm.chooseData.id ? parm.vm.chooseData.id : undefined,
        sourceData: parm.vm.chooseData,
        userId: this.$store.getters.userInfo.id,
        data: parm.data,
        dealApplyData: this.dealApplyData,
        applicableType: 'worker_certificate'
      })
    },
    dealData (data) {
      return !this.historyDate ? data : data.filter(v => new Date(v.getDate) <= this.historyDate)
    },
    async dataFileUpdate (parm, pageVm) {
      await request.update(pageVm.chooseData.id, {
        imgUploadTime: dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'})
      })
      pageVm.loadData()
    },
    initAddFormData () {
      return {
        workerId: this.workerId,
        type: 'pro_qua'
      }
    },
    async loadData () {
      let parm = {
        workerId: this.workerId,
        type: 'pro_qua'
      }
      let data = await request.get(parm)
      this.$emit('dataOk', data && data.length > 0)
      data.forEach(v => {
        v.typeText = (this.$store.getters.certificateTypeList.find(i => i.key === v.type) || {label: v.type}).label
        v.imgStatusText = v.imgUploadTime ? '已上传' : '未上传'
      })

      if (!data.length) {
        this.$emit('completeness', 0)
      } else {
        const keys = this.formParmsAdd.filter(v => !['type'].includes(v.key)).map(v => v.key)
        const num = data.reduce((a, item) => {
          return a + keys.reduce((c, key) => {
            if (typeof key === 'string' && ((Array.isArray(item[key]) && item[key].length) || (!Array.isArray(item[key]) && item[key]))) {
              return c + 1
            } else {
              return c
            }
          }, 0)
        }, 0)
        this.$emit('completeness', num / (data.length * keys.length))
      }

      return data
    }
  },
  computed: {
    dataFilefuns () {
      return {
        get: this.$authFunProxy['worker.worker_certificate-getDataFile'],
        upload: !this.readOnly && this.$authFunProxy['worker.worker_certificate-updateDataFile'],
        down: !this.readOnly && this.$authFunProxy['worker.worker_certificate-downDataFile'],
        del: !this.readOnly && this.$authFunProxy['worker.worker_certificate-delDataFile'],
        mkdir: !this.readOnly && this.$authFunProxy['worker.worker_certificate-mkdirDataFile']
      }
    },
    tableActions () {
      return this.readOnly ? [{key: 'data_file', label: '扫描件'}] : [{key: 'data_file', label: '扫描件'}, {key: 'edit', label: '修改'}, {key: 'del', label: '删除'}]
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          selectDatas: this.$store.getters.certificateTypeList,
          label: '证件类型',
          key: 'type',
          fmDisabled: true
        },
        {
          type: 'input',
          label: '证书编码',
          key: 'code'
        },
        {
          type: 'input',
          label: '管理号',
          key: 'gradSchool'
        },
        {
          type: 'datePicker',
          label: '授予时间',
          key: 'getDate'
        },
        {
          type: 'input',
          label: '系列名称/类别',
          key: 'xlmc'
        },
        {
          type: 'input',
          label: '专业名称',
          key: 'pro'
        },
        {
          type: 'select',
          selectDatas: this.$store.getters.certificateList,
          label: '资格证书',
          key: 'certificateId',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '工作单位',
          key: 'workerOrg'
        },
        {
          type: 'input',
          label: '证件状态',
          key: 'status'
        }]
        return data
      }
    },
    columnList () {
      let data = [{
          title: '证书编码',
          sort: true,
          field: 'code'
        },
        // {
        //   title: '管理号',
        //   sort: true,
        //   field: 'gradSchool'
        // },
        {
          title: '员工姓名',
          sort: true,
          field: 'workerName'
        },
        // {
        //   title: '工作单位',
        //   sort: true,
        //   field: 'workerOrg'
        // },
        {
          title: '系列名称/类别',
          sort: true,
          field: 'xlmc'
        },
        {
          title: '专业名称',
          sort: true,
          field: 'pro'
        },
        {
          title: '资格名称',
          sort: true,
          field: 'certificateTitle'
        },
        {
          title: '授予(批准)时间',
          sort: true,
          field: 'getDate',
          dataType: Date,
          width: 120,
          render: (h, rowData) => {
            return h('div', rowData && rowData.getDate ? rowData.getDate.slice(0, 10) : '-')
          },
          text: (rowData) => {
            return rowData && rowData.getDate ? rowData.getDate.slice(0, 10) : '-'
          }
        },
        {
          title: '批准文号',
          sort: true,
          field: 'practiceType'
        },
        {
          title: '发证时间',
          sort: true,
          field: 'hireStartDate',
          dataType: Date,
          width: 120,
          render: (h, rowData) => {
            return h('div', rowData && rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '-')
          },
          text: (rowData) => {
            return rowData && rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '-'
          }
        }]
      return data
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  }
}
</script>
