<template>
  <div class="content worker-data">
    <fm-title title-text="基础信息" v-if="workerData && (!type || type === 'base')"></fm-title>
    <fm-form :inline="4" label-alone label-align="left" v-if="workerData && (!type || type === 'base')" style="position: relative;">
      <fm-form-item label="姓名">
        <fm-input-new v-model="workerData.name" placeholder="姓名" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="性别">
        <fm-radio-group v-model="workerData.sex">
          <fm-radio value="男" :disabled="readOnly" label="男"></fm-radio>
          <fm-radio value="女" :disabled="readOnly" label="女"></fm-radio>
        </fm-radio-group>
      </fm-form-item>
      <fm-form-item label="民族">
        <fm-select absolute filterable :clearable="!readOnly" v-model="workerData.clan" :disabled="readOnly">
          <fm-option v-for="item in workerClanList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item v-if="workerData && workerData.id"></fm-form-item>
      <fm-form-item label="政治面貌">
        <fm-select absolute filterable :clearable="!readOnly" v-model="workerData.politicalStatus" :disabled="readOnly">
          <fm-option v-for="item in workerPoliticalStatusList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="身份证号码">
        <fm-input-new @change="idNoChange" placeholder="身份证号码" v-model="workerData.idNo" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="出生年月">
        <fm-date-picker
          absolute
          :readonly="readOnly"
          :value="workerData.birth"
          @change="birthChange"
          :clearable="!readOnly"
          placeholder="请选择"
          type="date"
          format="Y年M月D日"
        ></fm-date-picker>
      </fm-form-item>
      <fm-form-item v-if="workerData && workerData.id"></fm-form-item>
      <fm-form-item label="年龄">
        <fm-input-new v-model="workerData.age" placeholder="年龄" :disabled="true"/>
      </fm-form-item>
      <fm-form-item label="个人身份">
        <fm-select absolute filterable :clearable="!readOnly" v-model="workerData.sf" :disabled="readOnly || (workerApplyEditType1 && !(funAuth.edit || funAuth.add))">
          <fm-option v-for="item in workerIdentityList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="学历">
        <fm-select absolute filterable :clearable="!readOnly" v-model="workerData.education" :disabled="readOnly">
          <fm-option v-for="item in workerEducationList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item v-if="workerData && workerData.id"></fm-form-item>
      <fm-form-item label="学位">
        <fm-select absolute filterable :clearable="!readOnly" v-model="workerData.degree" :disabled="readOnly">
          <fm-option v-for="item in workerEduDegrees" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="籍贯">
        <fm-input-new placeholder="籍贯" v-model="workerData.hometown" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="联系电话">
        <fm-input-new placeholder="联系电话" v-model="workerData.phone" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item v-if="workerData && workerData.id"></fm-form-item>
      <fm-form-item label="是否特殊人才">
        <fm-select absolute filterable :clearable="!readOnly" v-model="workerData.agreementCode" :disabled="readOnly || (workerApplyEditType1 && !(funAuth.edit || funAuth.add))">
          <fm-option v-for="item in ['是', '否']" :key="item" :value="item" :label="item"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="是否港澳台及外籍人士">
        <fm-select absolute filterable :clearable="!readOnly" v-model="workerData.graduatedSchool" :disabled="readOnly">
          <fm-option v-for="item in ['是', '否']" :key="item" :value="item" :label="item"></fm-option>
        </fm-select>
      </fm-form-item>
      <worker-img v-if="workerData && workerData.id" :workerData="workerData" @headFileChange="headFileChange"></worker-img>
    </fm-form>
    <fm-title title-text="工作信息" v-if="workerData && (!type || type === 'work')"></fm-title>
    <fm-form :inline="4" label-alone label-align="left" v-if="workerData && (!type || type === 'work')" style="position: relative;">
      <fm-form-item label="工号">
        <fm-input-new v-model="workerData.code" placeholder="工号" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="机构名称">
        <fm-input-new v-model="workerData.orgName" placeholder="机构名称" :disabled="readOnly || !orgNameDisabled"/>
      </fm-form-item>
      <fm-form-item label="机构名称2" v-if="needOrgName2">
        <fm-input-new v-model="workerData.zg" placeholder="机构名称2" :disabled="readOnly || !orgName2Disabled"/>
      </fm-form-item>
      <fm-form-item label="所属职务">
        <org-select absolute filterable 
          :clearable="!readOnly" v-model="workerData.positionId" :disabled="readOnly">
        </org-select>
      </fm-form-item>
      <fm-form-item label="兼职职务">
        <OrgMultipleSelect absolute filterable 
          :clearable="!readOnly" v-model="workerData.positionIds" :disabled="readOnly">
        </OrgMultipleSelect>
      </fm-form-item>
      <fm-form-item label="默认展示机构等级">
        <fm-select absolute filterable :clearable="!readOnly" v-model="workerData.zhuanzhi" :disabled="readOnly">
          <fm-option v-for="item in orgTypeList.map(v => {return {key: 'orgTypeValue_' + v.data.id, label: v.data.name}})" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="是否在编">
        <fm-select absolute filterable :clearable="!readOnly" v-model="workerData.type" :disabled="readOnly">
          <fm-option v-for="item in ['在编', '非在编']" :key="item" :value="item" :label="item"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="在岗状态">
        <fm-select absolute filterable :clearable="!readOnly" v-model="workerData.jobTypeC" :disabled="readOnly">
          <fm-option v-for="item in workerZgStatus" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="工龄">
        <fm-input-new v-model="workerData.maritalStatus" :disabled="true"/>
      </fm-form-item>
      <fm-form-item label="参加工作时间">
        <fm-date-picker
          absolute
          :readonly="readOnly"
          :value="workerData.correctionDate"
          @change="(v) => workerData.correctionDate = v  ? v.replace(/年|月/g, '-').replace('日', '') : ''"
          :clearable="!readOnly"
          placeholder="请选择"
          type="date"
          format="Y年M月D日"
        ></fm-date-picker>
      </fm-form-item>
      <fm-form-item label="进入本单位时间">
        <fm-date-picker
          absolute
          :readonly="readOnly"
          :value="workerData.entryDate"
          @change="(v) => workerData.entryDate = v ? v.replace(/年|月/g, '-').replace('日', '') : ''"
          :clearable="!readOnly"
          placeholder="请选择"
          type="date"
          format="Y年M月D日"
        ></fm-date-picker>
      </fm-form-item>
      <fm-form-item label="人员类别">
        <fm-select absolute filterable :clearable="!readOnly" v-model="workerData.jobType" :disabled="readOnly">
          <fm-option v-for="item in workerJobTypes" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="入职方式">
        <fm-select absolute filterable :clearable="!readOnly" v-model="workerData.employmentForm" :disabled="readOnly">
          <fm-option v-for="item in employmentForms" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="离职方式">
        <fm-select absolute filterable :clearable="!readOnly" v-model="workerData.discipline" :disabled="readOnly || (workerApplyEditType1 && !(funAuth.edit || funAuth.add))">
          <fm-option v-for="item in workerResignList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="是否专业技术人员">
        <fm-select absolute filterable :clearable="!readOnly" v-model="workerData.address" :disabled="readOnly">
          <fm-option v-for="item in ['是', '否']" :key="item" :value="item" :label="item"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="专业技术人员专业名称" v-if="workerData.address === '是'">
        <fm-input-new v-model="workerData.shuangjiantiao" placeholder="专业技术人员专业名称" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="专业技术人员专业类别" v-if="workerData.address === '是'">
        <fm-select absolute filterable :clearable="!readOnly" v-model="workerData.email" :disabled="readOnly">
          <fm-option v-for="item in zyjsryzylb" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="是否卫生专业技术人员" v-if="workerData.address === '是'">
        <fm-select absolute filterable :clearable="!readOnly" v-model="workerData.jobTitle" :disabled="readOnly">
          <fm-option v-for="item in ['是', '否']" :key="item" :value="item" :label="item"></fm-option>
        </fm-select>
      </fm-form-item>
    </fm-form>
  </div>
</template>

<script>

import OrgSelect from '@/views/base/org/orgSelect'
import OrgMultipleSelect from '@/views/base/org/orgMultipleSelect'
import WorkerImg from './workerImg'

import {
  getBirthByIdNo,
  getAge
} from '@/syslib/tools'

export default {
  components: {
    OrgSelect,
    OrgMultipleSelect,
    WorkerImg
  },
  props: {
    sourceData: {
      type: Object
    },
    type: {
      type: String
    },
    funAuth: {
      type: Object
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      workerData: {}
    }
  },
  watch: {
    sourceData: {
      handler () {
        this.workerData = JSON.parse(JSON.stringify(this.sourceData))
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    positionList () {
      return this.$store.getters.positionList
    },
    orgNameDisabled () {
      return this.$store.getters.sysConfig['orgNameDisabled']
    },
    orgName2Disabled () {
      return this.$store.getters.sysConfig['orgName2Disabled']
    },
    needOrgName2 () {
      return this.$store.getters.sysConfig['needOrgName2']
    },
    workerApplyEditType1 () {
      return this.$store.getters.sysConfig['workerApplyEditType1']
    },
    workerJobTypes () {
      return this.$store.getters.selectDatas['worker_job_type']
    },
    employmentForms () {
      return this.$store.getters.selectDatas['employment_form']
    },
    zyjsryzylb () {
      return this.$store.getters.selectDatas['zyjsryzylb']
    },
    workerZgStatus () {
      return this.$store.getters.selectDatas['worker_zg_status']
    },
    workerEduDegrees () {
      return this.$store.getters.selectDatas['worker_edu_degree']
    },
    workerClanList () {
      return this.$store.getters.selectDatas['worker_clan_list']
    },
    workerPoliticalStatusList () {
      return this.$store.getters.selectDatas['worker_political_status_list']
    },
    workerIdentityList () {
      return this.$store.getters.selectDatas['worker_identity_list']
    },
    workerEducationList () {
      return this.$store.getters.selectDatas['worker_education_list']
    },
    workerResignList () {
      return this.$store.getters.selectDatas['worker_resign_list']
    },
    orgTypeList () {
      return this.$store.getters.orgTypeList
    },
    orgTypeIds () {
      return this.$store.getters.orgTypeList.map(v => v.data.id)
    }
  },
  methods: {
    headFileChange (fileData) {
      this.$set(this.workerData, 'headFileId', fileData.id)
    },
    idNoChange (data) {
      let birth = getBirthByIdNo(data)
      if (birth) {
        this.$set(this.workerData, 'birth', birth)
        this.$set(this.workerData, 'age', getAge(this.workerData.birth))
      }
    },
    birthChange (birth) {
      this.workerData.birth = birth ? birth.replace(/年|月/g, '-').replace('日', '') : ''
      this.$set(this.workerData, 'age', getAge(this.workerData.birth))
    },
    getFormData () {
      if (this.workerData.address !== '是') {
        this.workerData.shuangjiantiao = null
        this.workerData.email = null
        this.workerData.jobTitle = null
      }
      if (!this.workerData.entryDate) {
        this.workerData.entryDate = null
      }
      if (!this.workerData.correctionDate) {
        this.workerData.correctionDate = null
      }
      this.workerData.positionIds = this.workerData.positionIds && Array.isArray(this.workerData.positionIds) ? this.workerData.positionIds.join(',') : (this.workerData.positionIds || '')
      return this.workerData
    }
  }
}
</script>

<style scoped lang="less">
.img-c {
  height: 144px;
  width: 105px;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 1px solid #eee;
  .no-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #999;
  }
  img {
    max-height: 144px;
    max-width: 105px;
  }
}

.worker-data {
  .fm-form-inline {
    padding: 0 0 0 16px !important;
  }
}
</style>
