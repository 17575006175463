<template>
  <sign-block
    title-text="合同信息"
    :request="request"
    :titleMenus="readOnly ? [] : [{key: 'add', label: '新增'}]"
    :column-list="columnList"
    :data-file-parm="{pDirPath: '/agreement/'}"
    :form-parms-add="formParmsAdd"
    table-size="large"
    :tableActions="tableActions"
    :dataFilefuns="dataFilefuns"
    :form-parms-update="formParmsAdd"
    :initAddFormData="initAddFormData"
    :dealData="dealData"
    :initSearchData="{workerId: this.workerId}"
    :tableActionsFixed="true"
    :need-data-file="true">
  </sign-block>
</template>

<script>
import {
  agreementRequest as request
} from '@/api'

export default {
  props: {
    workerId: {
      type: [String, Number]
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    historyDate: {
      type: Date,
      default: null
    }
  },
  methods: {
    dealData (data) {
      return !this.historyDate ? data : data.filter(v => new Date(v.signedTime) <= this.historyDate)
    },
    initAddFormData () {
      return {
        workerId: this.workerId
      }
    }
  },
  computed: {
    dataFilefuns () {
      return {
        get: this.$authFunProxy['worker.agreement-getDataFile']
      }
    },
    tableActions () {
      return this.readOnly ? [{key: 'data_file', label: '扫描件'}] : [{key: 'edit', label: '修改'}, {key: 'del', label: '删除'}]
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          label: '合同类型',
          selectDatas: [{key: '劳动合同', label: '劳动合同'}, {key: '事业单位聘用合同', label: '事业单位聘用合同'}],
          key: 'type',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '合同编号',
          key: 'code',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '签订方式',
          key: 'signedType',
          selectDatas: [{key: '首签', label: '首签'}, {key: '续签', label: '续签'}],
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '聘用单位',
          key: 'signedOrg'
        },
        {
          type: 'datePicker',
          label: '签订时间',
          key: 'signedTime'
        },
        {
          type: 'datePicker',
          label: '起始时间',
          key: 'startDate',
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '到期时间',
          key: 'endDate'
        },
        {
          type: 'datePicker',
          label: '终止时间',
          key: 'stopDate'
        },
        {
          type: 'input',
          label: '终止原因',
          key: 'stopReson'
        },
        {
          type: 'datePicker',
          label: '解除时间',
          key: 'disDate'
        },
        {
          type: 'input',
          label: '解除原因',
          key: 'disReson'
        },
        {
          type: 'input',
          label: '合同状态',
          key: 'status'
        },
        {
          type: 'input',
          label: '变更原因',
          key: 'cReson'
        }]
        return data
      }
    },
    columnList () {
      let data = [{
        title: '编号',
        field: 'id',
        fixed: 'left',
        sort: true
      },
      {
        title: '合同编号',
        field: 'code',
        fixed: 'left',
        sort: true
      },
      {
        title: '合同类型',
        field: 'type',
        fixed: 'left',
        sort: true
      },
      {
        title: '员工姓名',
        field: 'workerName',
        sort: true
      },
      {
        title: '员工工号',
        field: 'workerCode',
        sort: true
      },
      {
        title: '签订方式',
        field: 'signedType',
        sort: true
      },
      {
        title: '聘用单位',
        field: 'signedOrg',
        sort: true
      },
      {
        title: '签订时间',
        field: 'signedTime',
        sort: true,
        width: 130,
        dataType: Date,
        render: (h, rowData) => {
          return h('div', rowData && rowData.signedTime ? rowData.signedTime.slice(0, 10) : '-')
        }
      },
      {
        title: '起始时间',
        field: 'startDate',
        sort: true,
        width: 130,
        dataType: Date,
        render: (h, rowData) => {
          return h('div', rowData && rowData.startDate ? rowData.startDate.slice(0, 10) : '-')
        }
      },
      {
        title: '到期时间',
        field: 'endDate',
        width: 130,
        sort: true,
        dataType: Date,
        render: (h, rowData) => {
          return h('div', rowData && rowData.endDate ? rowData.endDate.slice(0, 10) : '-')
        }
      },
      {
        title: '终止时间',
        field: 'stopDate',
        width: 130,
        sort: true,
        dataType: Date,
        render: (h, rowData) => {
          return h('div', rowData && rowData.stopDate ? rowData.stopDate.slice(0, 10) : '-')
        }
      },
      {
        title: '终止原因',
        field: 'stopReson',
        sort: true
      },
      {
        title: '解除时间',
        field: 'disDate',
        sort: true,
        width: 130,
        dataType: Date,
        render: (h, rowData) => {
          return h('div', rowData && rowData.disDate ? rowData.disDate.slice(0, 10) : '-')
        }
      },
      {
        title: '解除原因',
        field: 'disReson',
        sort: true
      },
      {
        title: '合同状态',
        field: 'status',
        sort: true
      },
      {
        title: '变更原因',
        field: 'cReson',
        sort: true
      },
      {
        title: '扫描件状态',
        field: 'fileStatus',
        sort: true,
        render: (h, rowData) => {
          return h('div', rowData && rowData.fileUploadTime ? '已上传' : '未上传')
        }
      },
      {
        title: '最近更新扫描件时间',
        field: 'fileUploadTime',
        dataType: Date,
        width: 155,
        sort: true
      }]
      return data
    }
  },
  data () {
    return {
      request: request
    }
  }
}
</script>
